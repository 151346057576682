<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row  bg-WeissTransparent">
          <div class="col-12 m-0 p-0">
            <carouselHeader></carouselHeader>
          </div>
        </div>
       
        <div class="row bg-WeissTransparent">
          <div class="col-12 text-Dunkelgrau pt-5">
             <!-- <div class="row justifyCenter m-0 p-0">
              <div class="col-md-8 text-Schwarz text-center border border-danger border-4 p-4">
                Der Vorstand und die Redaktion wünscht allen Mammillarienfreunden ein schönes Weihnachtsfest und einen guten Rutsch in das neue Jahr!
              </div>
            </div>  -->
            <div class="row justifyCenter m-0 p-0">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-12 text-Schwarz fs-4 mt-md-5 mb-5 ">
                    <p v-if="sprachID === 0">
                      Der Arbeitskreis für Mammillarienfreunde ist eine
                      Gesellschaft, die sich ausschließlich mit der
                      Kakteengattung Mammillaria und den verwandten Gattungen
                      Coryphantha, Escobaria und Neolloydia befasst.
                    </p>
                    <p v-if="sprachID === 1">
                      The "Arbeitskreis für Mammillarienfreunde" is a cactus
                      society which is involved solely with the genus
                      Mammillaria and the related genera Coryphantha, Escobaria
                      and Neolloydia.
                    </p>

                    <p v-if="sprachID === 0">
                      Der AfM wurde 1977 von neun deutschen
                      Mammillarien-Liebhabern gegründet und am 12. Dezember im
                      Vereinsregister der Stadt Münster eingetragen. Heute hat
                      der AfM ca. 300 Mitglieder in 18 Ländern. Dazu zählen
                      neben Einzelpersonen auch eine Anzahl von Botanischen
                      Gärten und anderen Institutionen in Europa und Übersee.
                    </p>
                    <p v-if="sprachID === 1">
                      The AfM was founded in 1977 by nine German
                      Mammillaria-enthusiasts and was officially registered in
                      the city of Muenster on December 12. At present, the AfM
                      has about 300 members in 18 different countries. Among
                      these members are personal members, but also a number of
                      botanical gardens and institutions in Europe and abroad.
                    </p>

                    <p v-if="sprachID === 0">
                      Im Jahre 1990 vereinigten sich die beiden Vereine
                      ZAG-Mammillaria (gegründet 1970) aus der ehemaligen DDR
                      und der AfM. Das vom AfM herausgegebene Mitteilungsblatt
                      MAMMILLARIA ist ein wichtiges Kommunikations- und
                      Arbeitsmittel für die Mitglieder, die Ergebnisse ihrer
                      Untersuchungen am Standort, im Gewächshaus oder in ihrer
                      Bibliothek auszutauschen.
                    </p>
                    <p v-if="sprachID === 1">
                      In the year 1990 the two societies, ZAG-Mammillarien
                      (founded in 1970, from the former German Democratic
                      Republic) and the AfM, were joined. The journal
                     
                        Mitteilungsblatt MAMMILLARIA
                     
                      edited by the AfM, is an important means of communication
                      for the members to exchange the results of their
                      investigations at the natural locations, in the greenhouse
                      or in their library.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row bg-WeissTransparent">
          <div class="col-12 text-Dunkelgrau p-0">
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div class="row">
                  <div class="col-md-4 text-Weiss lh-sm">
                    <div class="m-2 p-4 bg-Dunkelgrau" style="height: 100%">
                      <p class="text-center">
                        <i class="far fa-clock" style="font-size: 36px"></i>
                      </p>
                      <p class="text-center fs-4" v-if="sprachID === 0">
                         <br /><br />
                        Die JHV 2025 findet vom 02.-04.05.2025 statt
                        <br/>
                        <br/>
                        -----------------
                        <br/>
                        Siehe unter Veranstaltungen
                      </p>
                      <p class="text-center fs-4" v-if="sprachID === 1">
                         <br /><br />The autumn conference will take place from October 11th to 13th, 2024 in Aufenau
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4 text-Weiss lh-sm">
                    <div class="m-2 p-4 bg-HellGruen" style="height: 100%">
                      <p class="text-center fs-3">Mitteilungsblatt 4.2024</p>
                      <p class="text-center fs-4">
                        <img
                          src="../assets/Mitteilungsblatt/Titelbild/2024-4.jpg"
                          class="Mitteilungsblattfoto zoom"
                          alt="..."
                        />
                      </p>
                      <p class="text-center text-Schwarz fs-6">
                        <ul class="koUL noDeko">
                        <li class="borderRund" @click="openPDF('Probeartikel/Probeartikel_4_2024.pdf')">
                          <span v-if="sprachID === 0"> Probeartikel</span>
                          <span v-if="sprachID === 1"> sample item</span>
                        </li> 
                        <li class="borderRund" @click="openPDF('IHV/IHVHeft42024.pdf')">
                          <span v-if="sprachID === 0"> Inhaltsverzeichnis</span>
                          <span v-if="sprachID === 1">Table of Contents</span>
                        </li>              
                      </ul>
                        
                      </p>
                      
                    </div>
                  </div>
                  <div class="col-md-4 text-Weiss lh-sm">
                    <div class="m-2 p-4 bg-Dunkelgrau" style="height: 100%">
                      <p class="text-center">
                        <i class="far fa-clock" style="font-size: 36px"></i>
                      </p>
                      <p class="text-center fs-4"  v-if="sprachID === 0">
                        
                        <br /><br />
                        Die neue Samenliste 2025 ist jetzt im Shop online

                      </p>
                      <p class="text-center fs-4"  v-if="sprachID === 1">
                        <br /><br />
                        The new seed list 2025 is now online in the shop
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
            <div class="row justifyCenter mt-5 ">
              <div class="col-md-6  bg-Dunkelgrau p-3">
                <p class="text-center text-Weiss-Schatten fs-4"  v-if="sprachID === 0">
                Neuigkeiten
              </p>
              <p class="text-center text-Weiss-Schatten fs-4"  v-if="sprachID === 1">
                News
              </p>
              <p>
                <carouselNews></carouselNews>
              </p>
            </div>
            </div>

            <div class="row justifyCenter mt-5 ">
              <div class="col-md-6  bg-HellGruen p-3">
                <p class="text-center text-Weiss-Schatten fs-4"  >
                Mitarbeit bei unserem FACEBOOK- Auftritt gesucht
              </p>
              <ul class="koUL noDeko">
                <li class="borderRund" @click="openFacebook()">
                  <span v-if="sprachID === 0"> Bilder Upload hier </span>
                  <span v-if="sprachID === 1"> Picture upload here </span>
                </li> 
              </ul>
              </div>
            </div>

            <div class="row mt-5 p-4 m-0 justifyCenter bg-DunkelgrauTransparent">
              <div class="col-md-10 text-Weiss h2 fw-bold">
               <span v-if="sprachID === 0">UNSERE LEISTUNGEN</span>
               <span v-if="sprachID === 1">OUR SERVICES</span>
              </div>
            </div>
            <div class="row justifyCenter">
              <div class="col-md-6 bg-Weiss pt-5 pb-4 ps-md-5 mt-4 fs-5">
                <ul>
                  <li v-if="sprachID === 0">
                    Die Loseblattsammlung (LBS) des AfM ist ein ausführliches
                    Sammelwerk zu Arten, Unterarten, Varietäten und Synonymen,
                    das jährlich ergänzt wird.
                  </li>
                  <li v-if="sprachID === 1">
                    The loose-leaf collection (LBS) of the AfM is a detailed one
                     Collection of species, subspecies, varieties and synonyms,
                     which is added annually.
                  </li>
                  <li v-if="sprachID === 0">
                    Jährliche Tagungen im Frühjahr und im Herbst geben den
                    Mitgliedern die Möglichkeit, bei Vorträgen und im geselligen
                    Rahmen über ihre Erfahrungen zu diskutieren.
                  </li>
                  <li v-if="sprachID === 1">
                    Annual conferences in spring and autumn give the
                     members the opportunity to attend lectures and socialize
                     frame to discuss their experiences.
                  </li>
                  <li v-if="sprachID === 0">
                    Jedes Mitglied kann Bücher, Zeitschriften und andere
                    Veröffentlichungen aus der ständig anwachsenden Bibliothek
                    des Vereins ausleihen.
                  </li>
                  <li v-if="sprachID === 1">
                    Each member can book, magazine and others
                     Publications from the constantly growing library
                     borrow from the club.
                  </li>
                  <li v-if="sprachID === 0">
                    Von Mitgliedern gespendetes Saatgut wird durch das Team des
                    Samenfonds aufbereitet und in einer Samenliste angeboten,
                    die unter Shop/Samenfond veröffentlicht wird.
                  </li>
                  <li v-if="sprachID === 1">
                    Seeds donated by members are processed by the team at the
                     Seed fund prepared and offered in a seed list,
                     which is published under shop/seed fund.
                  </li>
                  <li v-if="sprachID === 0">
                    Im REM-Archiv hält der AfM eine wachsende Anzahl von
                    Raster-Elektronen-Mikroskop (REM)-Aufnahmen von Samen vor,
                    die Mitgliedern bei Bedarf zur Verfügung gestellt werden.
                  </li>
                  <li v-if="sprachID === 1">
                    In the REM archive, the AfM keeps a growing number of
                     Scanning electron microscope (SEM) images of seeds,
                     made available to members as needed.
                  </li>
                </ul>
              </div>
            </div>

            <div class="row ">
              <div
                class="col-12 text-Dunkelgrau pt-4 ps-5 mb-0 h3 fw-bold"
              ></div>
            </div>
            <div class="row  m-0  nurBildschirm">
              <div class="col-8 offset-2 pb-5 carusell ">
                <carouselHome></carouselHome>                
              </div>
              
            </div>
            <div class="row  m-0 nurHandy">              
              <div class="col-12  pb-5 carusell ">                
                <carouselHomeHandy></carouselHomeHandy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import carouselHome from "../components/carouselHome.vue";
import carouselHomeHandy from "../components/carouselHomeHandy.vue";
import carouselHeader from "../components/carouselHeader.vue";
import carouselNews from "../components/carouselNews.vue";
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";
import modalFacebook from "../components/ModalFacebook.vue";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
    };
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  components: {
    Layout,
    carouselHome,
    carouselHomeHandy,
    carouselHeader,
    carouselNews,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },   
    openFacebook() {
      
      openModal(modalFacebook);
    },
  },
};
</script>

<style scoped></style>
