<template #default>
  <div class="container-fluid p-0 m-0 background1">
    <div class="row bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-6 mt-5 p-0">
        <h1 v-if="sprachID === 0">Aktuelles</h1>
        <h1 v-if="sprachID === 1">Board of Directors of the AFM</h1>
      </div>
    </div>
    <div class="row bg-WeissTransparent pb-5 justifyCenter">
      <div class="col-12 col-md-6">
        <table width="100%" cellspacing="5" >             
          <tr>
            <td valign="top">15.01.2025</td>
            <td>Samenliste 2025<br /></td>
          </tr>                 
          <tr>
            <td valign="top">10.12.2024</td>
            <td>Heft 4-2024<br /></td>
          </tr>                
          <tr>
            <td valign="top">10.09.2024</td>
            <td>Heft 3-2024<br /></td>
          </tr>      
          <tr>
            <td valign="top">13.08.2024</td>
            <td>Termin JHV 2025<br /></td>
          </tr>
          <tr>
            <td valign="top">25.07.2024</td>
            <td>Heft 2-2024<br /></td>
          </tr>
          <tr>
            <td valign="top">26.05.2024</td>
            <td>Heft 1-2024<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2024</td>
            <td>Samenliste 2024<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2024</td>
            <td>Heft 4-2023<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2024</td>
            <td>JHV-2024<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2024</td>
            <td>Jahresübersichten<br /></td>
          </tr>  
          <tr>
            <td valign="top">05.12.2023</td>
            <td>Literaturliste 2023<br /></td>
          </tr>  
          <tr>
            <td valign="top">02.04.2023</td>
            <td>neue Einladung Herbsttagung 23<br /></td>
          </tr>  
          <tr>
            <td valign="top">02.04.2023</td>
            <td>Heft 1 2023<br /></td>
          </tr>
          <tr>
            <td valign="top">15.01.2023</td>
            <td>neue Hotelinformationen JHV 23<br /></td>
          </tr>
          <tr>
            <td valign="top">17.12.2022</td>
            <td>Heft 4-2022<br /></td>
          </tr>
          <tr>
            <td valign="top">05.09.2022</td>
            <td>Facebook Hinweis und Bilderupload<br /></td>
          </tr>
          <tr>
            <td valign="top">16.08.2022</td>
            <td>www.vkw-kakteen.de übernommen<br /></td>
          </tr>
          <tr>
            <td valign="top">29.07.2022</td>
            <td>Termine Herbsttagung<br /></td>
          </tr>
          <tr>
            <td valign="top">28.07.2022</td>
            <td>new website published<br /></td>
          </tr>
         <tr>
            <td valign="top">15.05.2022</td>
            <td>Heft 2-2022<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2022</td>
            <td>Heft 4-2021<br /></td>
          </tr>
          <tr>
            <td valign="top">16.10.2021</td>
            <td>
              Heft 2-2021<br />
              Heft 3-2021<br />
            </td>
          </tr>
          <tr>
            <td valign="top">10.3.2021</td>
            <td>Heft 1-2021<br /></td>
          </tr>
          <tr>
            <td valign="top">15.1.2021</td>
            <td>Samenliste 2021<br /></td>
          </tr>
          <tr>
            <td valign="top">18.1.2020</td>
            <td>Heft 4-2020<br /></td>
          </tr>
          <tr>
            <td valign="top">08.11.2020</td>
            <td>Einladung Herbsttagung 2020 entfernt<br /></td>
          </tr>
          <tr>
            <td valign="top">10.10.2020</td>
            <td>Heft 3-2020<br /></td>
          </tr>
          <tr>
            <td valign="top">17.07.2020</td>
            <td>Ablauf Herbsttagung 2020<br /></td>
          </tr>
          <tr>
            <td valign="top">24.06.2020</td>
            <td>Heft 02.2020<br /></td>
          </tr>
          <tr>
            <td valign="top">20.03.2020</td>
            <td>
              Jahres HV 04.2020 abgesagt<br />
              Heft 01.2020<br />
            </td>
          </tr>
          <tr>
            <td valign="top">26.02.2020</td>
            <td>
              Samenliste 2020<br />
              Feldliste Linzen 2020<br />
            </td>
          </tr>
          <tr>
            <td valign="top">15.01.2020</td>
            <td>
              Programmablauf JHV 43 <br />
              JAhresinhaltsverzeichnis 2019 und Namensverzeichnis 2019 <br />
            </td>
          </tr>
          <tr>
            <td valign="top">02.01.2020</td>
            <td>Lieferprobleme Heft 4 <br /></td>
          </tr>
          <tr>
            <td valign="top">28.12.2019</td>
            <td>Heft 4.2019<br /></td>
          </tr>
          <tr>
            <td valign="top">07.09.2019</td>
            <td>Heft 3.2019<br /></td>
          </tr>
          <tr>
            <td valign="top">12.06.2019</td>
            <td>Heft 2.2019<br /></td>
          </tr>
          <tr>
            <td valign="top">15.05.2019</td>
            <td>Jahreshaupversammlung 2020<br /></td>
          </tr>
          <tr>
            <td valign="top">24.03.2019</td>
            <td>encoding Probleme beseitigt<br /></td>
          </tr>
          <tr>
            <td valign="top">14.03.2019</td>
            <td>Programm JHV<br /></td>
          </tr>
          <tr>
            <td valign="top">08.03.2019</td>
            <td>Heft 1.2019<br /></td>
          </tr>
          <tr>
            <td valign="top">20.01.2019</td>
            <td>Samenliste 2019</td>
          </tr>
          <tr>
            <td valign="top">16.10.2018</td>
            <td>Reparatur Onlineformulare</td>
          </tr>
          <tr>
            <td valign="top">28.09.2018</td>
            <td>
              Sonderheft 2018 <br />
              JHV 2019<br />
            </td>
          </tr>
          <tr>
            <td valign="top">25.09.2018</td>
            <td>Berichte 41.JHV <br /></td>
          </tr>
          <tr>
            <td valign="top">25.09.2018</td>
            <td>Heft 3.2018<br /></td>
          </tr>
          <tr>
            <td valign="top">26.06.2018</td>
            <td>Heft 2.2018<br /></td>
          </tr>
          <tr>
            <td valign="top">07.06.2018</td>
            <td>Datenschutzerklärung und Impressum eingefügt<br /></td>
          </tr>

          <tr>
            <td valign="top">27.12.2017</td>
            <td>Namensliste 2017<br /></td>
          </tr>
          <tr>
            <td valign="top">20.12.2017</td>
            <td>Heft 4.2017<br /></td>
          </tr>
          <tr>
            <td valign="top">22.09.2017</td>
            <td>Heft 3.2017<br /></td>
          </tr>
          <tr>
            <td valign="top">26.04.2017</td>
            <td>
              Aktuelles zur Herbsttagung<br />
              Heft 2.2017<br />
              neue Bibliothek im internen Bereich<br />
              redesign intern<br />
            </td>
          </tr>
          <tr>
            <td valign="top">26.04.2017</td>
            <td>Aktuelles zur Herbsttagung<br /></td>
          </tr>
          <tr>
            <td valign="top">13.04.2017</td>
            <td>Heft 1.2017<br /></td>
          </tr>
          <tr>
            <td valign="top">08.02.2017</td>
            <td>Neue Mitgliedsnummern<br /></td>
          </tr>
          <tr>
            <td valign="top">17.01.2017</td>
            <td>Neue Samenliste 2017 <br /></td>
          </tr>
          <tr>
            <td valign="top">09.01.2017</td>
            <td>
              Ausweichhotel JHV <br />
              Feldliste Linzenv <br />
              Pflanzenverzeichnis 2016 <br />
            </td>
          </tr>
          <tr>
            <td valign="top">29.11.2016</td>
            <td>Heft 4.2016</td>
          </tr>
          <tr>
            <td valign="top">20.09.2016</td>
            <td>Heft 3.2016</td>
          </tr>
          <tr>
            <td valign="top">26.07.2016</td>
            <td>Heft 2.2016</td>
          </tr>
          <tr>
            <td valign="top">07.06.2016</td>
            <td>JHV 2016</td>
          </tr>
          <tr>
            <td valign="top">07.06.2016</td>
            <td>Schriftführer ersetzt</td>
          </tr>
          <tr>
            <td valign="top">09.05.2016</td>
            <td>Änderung Veranstaltungen</td>
          </tr>
          <tr>
            <td valign="top">11.04.2016</td>
            <td>Hinweis Druckerei</td>
          </tr>
          <tr>
            <td valign="top">04.04.2016</td>
            <td>Heft 1.2016</td>
          </tr>
          <tr>
            <td valign="top">03.03.2016</td>
            <td>Änderung Bankverbindung</td>
          </tr>
          <tr>
            <td valign="top">06.01.2016</td>
            <td>Heft 4.2015</td>
          </tr>
          <tr>
            <td valign="top">18.11.2015</td>
            <td>Protokolle Jahreshauptversammlung 2015</td>
          </tr>
          <tr>
            <td valign="top">26.10.2015</td>
            <td>Verzeichnis Loseblattsammlung 2014</td>
          </tr>
          <tr>
            <td valign="top">18.09.2015</td>
            <td>neues Heft 3.2015</td>
          </tr>
          <tr>
            <td valign="top">16.06.2015</td>
            <td>neues Heft 2.2015</td>
          </tr>
          <tr>
            <td valign="top">10.04.2015</td>
            <td>Neue Paypalverbindung</td>
          </tr>
          <tr>
            <td valign="top">27.03.2015</td>
            <td>neues Heft 1.2015</td>
          </tr>
          <tr>
            <td valign="top">03.03.2015</td>
            <td>Nachruf Dr. Bill Maddams</td>
          </tr>
          <tr>
            <td valign="top">02.03.2015</td>
            <td>neue Kontonummer</td>
          </tr>
          <tr>
            <td valign="top">19.01.2015</td>
            <td>neue Samenliste 2015</td>
          </tr>
          <tr>
            <td valign="top">18.12.2014</td>
            <td>Mitteilungsblatt 4.2014</td>
          </tr>
          <tr>
            <td valign="top">17.12.2014</td>
            <td>Aufruf Biliothek</td>
          </tr>
          <tr>
            <td valign="top">20.11.2014</td>
            <td>Protokoll außerordentliche Sitzung 2014</td>
          </tr>
          <tr>
            <td valign="top">17.11.2014</td>
            <td>Änderung Beitrag</td>
          </tr>
          <tr>
            <td valign="top">17.11.2014</td>
            <td>
              Inhaltsverzeichnis Planzennamen 2012 <br />
              Protokoll JHV 2014 <br />
              Vorankündigung JHV 2015
            </td>
          </tr>
          <tr>
            <td valign="top">30.10.2014</td>
            <td>Neues Vostandsmitglied Kassierer</td>
          </tr>
          <tr>
            <td valign="top">13.10.2014</td>
            <td>Mitteilungsblatt 3.2014</td>
          </tr>
          <tr>
            <td valign="top">11.08.2014</td>
            <td>
              Zusatzinformationen Herbsttagung 2014 <br />
              Mitteilungsblatt 2.2014
            </td>
          </tr>
          <tr>
            <td valign="top">02.08.2014</td>
            <td>Zusatzinformationen Herbsttagung 2014 <br /></td>
          </tr>
          <tr>
            <td valign="top">22.04.2014</td>
            <td>Mitteilungsblatt 1.14 eingefügt <br /></td>
          </tr>
          <tr>
            <td valign="top">06.01.2014</td>
            <td>
              Mitteilungsblat 4.13 eingefügt <br />
              Adressänderung Leiter Heftversands
            </td>
          </tr>
          <tr>
            <td valign="top">16.10.2013</td>
            <td>Namensänderungen in Mitteilungsblatt</td>
          </tr>
          <tr>
            <td valign="top">10.10.2013</td>
            <td>Mitteilungsblat 3.13 eingefügt</td>
          </tr>
          <tr>
            <td valign="top">13.06.2013</td>
            <td>Mitteilungsblat 2.13 eingefügt</td>
          </tr>
          <tr>
            <td valign="top">23.05.2013</td>
            <td>
              Änderung Vorstand <br />
              Änderung Antragsformular Neuanmeldung <br />
              Hinweis Anstecknadel
            </td>
          </tr>
          <tr>
            <td valign="top">19.04.2013</td>
            <td>Caching Verhalten geändert</td>
          </tr>
          <tr>
            <td valign="top">16.04.2013</td>
            <td>Heft 01-13 hinzugefügt</td>
          </tr>
          <tr>
            <td valign="top">04.03.2013</td>
            <td>Samenliste 2013 hinzugefügt</td>
          </tr>
          <tr>
            <td valign="top">04.03.2013</td>
            <td>REM-liste erneuert</td>
          </tr>
          <tr>
            <td valign="top">12.02.2013</td>
            <td>
              Link Kakateenforum <br />
              eingefügt
            </td>
          </tr>
          <tr>
            <td valign="top">02.01.2013</td>
            <td>
              Programm Jahreshauptversammlung<br />
              eingefügt
            </td>
          </tr>
          <tr>
            <td valign="top">14.11.2012</td>
            <td>
              Heft 4-2012 eingefügt<br />
              2 Bilder Herbsttagung eingefügt
            </td>
          </tr>
          <tr>
            <td valign="top">17.09.2012</td>
            <td>
              Satzung eingefügt<br />
              Heft 3-2012 eingefügt<br />
              Programm und Berichte im internen Bereich eingefügt
            </td>
          </tr>
          <tr>
            <td valign="top">31.07.2012</td>
            <td>Einladung Herbsttagung</td>
          </tr>

          <tr>
            <td valign="top">18.06.2012</td>
            <td>Jahresheft 1-12 eingefügt</td>
          </tr>
          <tr>
            <td valign="top">08.05.2012</td>
            <td>aktualisierte Samenliste eingefügt</td>
          </tr>
          <tr>
            <td valign="top">08.05.2012</td>
            <td>Inhaltsverzeichnis 2011 eingefügt</td>
          </tr>
          <tr>
            <td valign="top">17.04.2012</td>
            <td>Literaturauswertung entfernt</td>
          </tr>
          <tr>
            <td valign="top">06.01.2012</td>
            <td>Jahresheft 4-2011 eingefügt</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Satzung",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
